import React, {FunctionComponent, useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {Link, useHistory} from 'react-router-dom'
import {confirmAlert} from 'react-confirm-alert'

import envVariable from '../../util/envVariable'
import Back from '../svg/Back'
import FetchFolderGateway from '../../../gateway/Folder/FetchFolderGateway'
import ConfirmationModalWithReason from '../modal/ConfirmationModelWithReason'
import ButtonDropdown from '../button/ButtonDropdown'
import { SubscriberScopeEnum } from '../../../domain/CustomerInfoRecall/SubscriberScopeEnum'
import { ENotificationType } from '../notification/ENotificationType'
import Notification from '../notification/Notification'
import GetFolderPairingUseCase from '../../../useCase/Folder/GetFolderPairingUseCase'
import Logout from '../svg/Logout'
import UserProfile from './UserProfile'

type Props = {
  dossierId: string
}

const HeaderManageComponent: FunctionComponent<Props> = ({dossierId}) => {
  const {t} = useTranslation()
  const logo = `${envVariable('REACT_APP_API_HOST')}/theme/images/logo.png`;
  const [state, setState] = useState<string | null>(null)
  const [errorMessage, setErrorMessage] = useState<string|null>(null)
  const history = useHistory()

  useEffect(() => {
    new FetchFolderGateway().retrieveState(dossierId).then((response) => {
      setState(response)
    })
  }, [dossierId])

  useEffect(() => {
    if (errorMessage) {
      setTimeout(() => setErrorMessage(null), 3000)
    }
  }, [errorMessage])


  const handleValidate = async () => {
    const folderGateway = new FetchFolderGateway()
    const folderDetails = await folderGateway.getSummary(dossierId).then((response) => {return response})
    if (folderDetails) {
        if ('mandate' === folderDetails.detention_mode) {
          const pairingUseCase = new GetFolderPairingUseCase(new FetchFolderGateway())
          const pairing = await pairingUseCase.handle(dossierId).then((response) => { return response })
          if (folderDetails.dismemberment_scope === SubscriberScopeEnum.NP) {
            if (!pairing || !pairing.usu) {
              setErrorMessage(t('validation.error.usufruct-pairing-required'));
              return
            }
          } else {
            if (!pairing || !pairing.np) {
              setErrorMessage(t('validation.error.bareowner-pairing-required'));
              return
            }
          }
        }
    }
    folderGateway.updateDossierIntegrationState(dossierId, 'validated', null).then(response => {
      if (response) {
        window.location.href = `/manage-dossier/${dossierId}`
      }
    })
  }

  const confirmHandleRefused = () => {
    confirmAlert({
      customUI: ({onClose}) => {
        return <ConfirmationModalWithReason onConfirm={(message) => handleRefused(message)} onClose={onClose}/>
      },
    })
  }

  const handleRefused = (reason: string | null) => {
    (new FetchFolderGateway()).updateDossierIntegrationState(dossierId, 'refused', reason).then(response => {
      if (response) {
        window.location.href = `/manage-dossier/${dossierId}`
      }
    })
  }

  const confirmHandleError = () => {
    confirmAlert({
      customUI: ({onClose}) => {
        return <ConfirmationModalWithReason onConfirm={(message) => handleError(message)} onClose={onClose}/>
      },
    })
  }

  const handleError = (reason: string | null) => {
    (new FetchFolderGateway()).updateDossierIntegrationState(dossierId, 'error', reason).then(response => {
      if (response) {
        window.location.href = `/manage-dossier/${dossierId}`
      }
    })
  }

  const actions = [
    { label: t('validation.decline'), classes: `dropdown-button__error u-txt-uppercase u-pys`, onClick: () => confirmHandleRefused() },
    { label: t('validation.error-state'), classes: `dropdown-button__warning u-txt-uppercase u-pys`, onClick: () => confirmHandleError() },
    { label: t('validation.accept'), classes: `dropdown-button__success u-txt-uppercase u-pys`, onClick: () => handleValidate() }
  ];

  const previousPage = localStorage.getItem("previousPage") || "/tableau-de-bord";

  return (
    <div className="main-header main-header--validation-rcci u-mxl">
      <div className="flex-container middle-xs no-compensation">
        <h1 className="u-mb0 u-mrl">
          <a href="#">
            <img src={logo} alt="Société d'investissement" style={{maxWidth: '150px'}}/>
          </a>
        </h1>
        <Link to="#" className="back" onClick={(event) => {
          event.preventDefault();
          history.push(previousPage)
        }}>
          <Back color="var(--ink-1)" />
          <span className="u-txt-size-l u-txt-color-ink-1 u-mxs">{t('button.back-home')}</span>
        </Link>
      </div>
      <div className="flex-container middle-xs left-xs u-txt-size-l">
        <div className={`u-mb0`}>
        {
          errorMessage && <Notification label={errorMessage} type={ENotificationType.error} canClose={false}/>
        }
        </div>
      </div>
      <div className="flex-container middle-xs end-xs">
        { ("ongoing" === state || "error" === state) && <ButtonDropdown label={t('validation.rcci')} actions={actions} className={"button--rcci-validation"}/> }
      </div>
      <div className="flex-container middle-xs end-xs">
        <UserProfile hasIcon={false}/>
        <div className="button__container button__container--logout">
          <Link to="/logout" className="button button-secondary button--medium button--logout u-pxs"><span className="text-logout">{t('button.logout')}</span> <Logout/></Link>
        </div>
      </div>
    </div>
  )
}

export default HeaderManageComponent
